<template>
  <button
    type="button"
    :disabled="loading || disabled"
    :class="[buttonClasses, loading ? 'inline-flex items-center justify-center gap-x-1.5' : '']"
  >
    <slot v-if="!loading">Bestätigen</slot>
    <ArrowPathIcon v-if="loading" class="-ml-0.5 h-5 w-5 animate-spin" aria-hidden="true"></ArrowPathIcon>
    <span v-if="loading">{{ loadingText }}</span>
  </button>
</template>
<script setup lang="ts">
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { ButtonSize, ButtonType } from '~/types/Button'

const {
  type = ButtonType.primary,
  size = ButtonSize.md,
  loading = false,
  loadingText = 'wird geladen...',
  disabled = false
} = defineProps<{
  type?: ButtonType | string
  size?: ButtonSize | string
  loading?: boolean
  loadingText?: string
  disabled?: boolean
}>()

const buttonClasses = computed(() => {
  switch (type) {
    case ButtonType.primary: {
      const defaultClasses =
        'hover:bg-meevo-blue-400 text-white shadow-sm font-semibold bg-meevo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-blue-500 disabled:bg-meevo-blue-400 disabled:cursor-not-allowed'
      switch (size) {
        case ButtonSize.xs: {
          return defaultClasses + ' rounded px-2 py-1 text-xs'
        }
        case ButtonSize.sm: {
          return defaultClasses + ' rounded px-2 py-1 text-sm'
        }
        case ButtonSize.md: {
          return defaultClasses + ' rounded-md px-2.5 py-1.5 text-sm'
        }
        case ButtonSize.lg: {
          return defaultClasses + ' rounded-md px-3 py-2 text-sm'
        }
        case ButtonSize.xl: {
          return defaultClasses + ' rounded-md px-3.5 py-3 text-sm'
        }
        case ButtonSize.xxl: {
          return defaultClasses + ' rounded-md px-5 py-4 text-base'
        }
      }
      break
    }
    case ButtonType.secondary: {
      switch (size) {
        case ButtonSize.xs: {
          return 'rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        }
        case ButtonSize.sm: {
          return 'rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        }
        case ButtonSize.md: {
          return 'rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        }
        case ButtonSize.lg: {
          return 'rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        }
        case ButtonSize.xl: {
          return 'rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        }
      }
      break
    }
    case ButtonType.callToAction: {
      switch (size) {
        case ButtonSize.xs: {
          return 'rounded bg-meevo-red-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-meevo-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-red-700'
        }
        case ButtonSize.sm: {
          return 'rounded bg-meevo-red-700 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-meevo-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-red-700'
        }
        case ButtonSize.md: {
          return 'rounded-md bg-meevo-red-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-meevo-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-red-700'
        }
        case ButtonSize.lg: {
          return 'rounded-md bg-meevo-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-meevo-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-red-700'
        }
        case ButtonSize.xl: {
          return 'rounded-md bg-meevo-red-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-meevo-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-red-700'
        }
      }
      break
    }
    // type: primary, size: md
    default: {
      return 'rounded-md bg-meevo-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-meevo-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-meevo-blue-500'
    }
  }
  return ''
})
</script>
