export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl'
}

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  callToAction = 'call-to-action',
  soft = 'soft',
  leadingIcon = 'leading-icon',
  trailingIcon = 'trailing-icon',
  roundedPrimary = 'rounded-primary',
  roundedSecondary = 'rounded-secondary',
  circular = 'circular'
}
